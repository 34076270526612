import * as Icons from '../components/Icons'

export default {
  linkedin: {
    icon: Icons.LinkedIn,
    text: 'LinkedIn',
  },
  github: {
    icon: Icons.GitHub,
    text: 'GitHub',
  },
  // stackoverflow: {
  //   icon: Icons.StackOverflow,
  //   text: 'StackOverflow',
  // },
  // instagram: {
  //   icon: Icons.Instagram,
  //   text: 'Instagram',
  // },
  twitter: {
    icon: Icons.Twitter,
    text: 'Twitter',
  },
}
