import React from 'react'
import { css } from '@emotion/react'
import { animated, useTrail } from 'react-spring'

import Social from './Social'

const home = css`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding-bottom: 5vh;
  padding-right: 3rem;
  max-width: 30rem;

  @media (min-width: 30em) {
    justify-content: center;
  }
`

const logo = css`
  margin-bottom: 1em;
`

const intro = css`
  opacity: 0.8;
`

const config = { mass: 1, tension: 120, friction: 14 }

const toStyle = ({ y, ...rest }) => ({
  ...rest,
  transform: y.interpolate(value => `translate3d(0,${value}px,0)`),
})

export default function Home() {
  const trail = useTrail(4, {
    config,
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: -10 },
  })

  return (
    <div css={home}>
      <animated.h1 css={logo} style={toStyle(trail[0])}>
        Dalton G.
      </animated.h1>
      <div css={intro}>
        <animated.p style={toStyle(trail[1])}>
          Hi, I’m Dalton Garbin,  FullStack Developer from
          Sydney, Australia. I currently think of .NET Core and React.
        </animated.p>
        <animated.p style={toStyle(trail[2])}>
          Enthusiastic reader, flexible musician, casual runner, Open Source fan
          and community lover.
          <br />
          Send me an email at{' '}
          <a href="mailto:daltongarbin@gmail.com">
            daltongarbin@gmail.com
          </a>{' '}
          for contact or follow me on social networks :)
        </animated.p>
      </div>
      <animated.div style={toStyle(trail[3])}>
        <Social />
      </animated.div>
    </div>
  )
}
